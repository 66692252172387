const isApproved = vehicle => {
  let mileage = vehicle.mileage;
  let make = vehicle.make.toLowerCase();
  let comm = new Date(vehicle.firstRegistrationDate);
  let now = new Date();
  let max_mileage = 185000;
  let max_years = 10;

  if (make !== "toyota") {
    return false;
  }
  if (
    now.getTime() / 1000 - comm.getTime() / 1000 >
    max_years * 365 * 24 * 60 * 60
  ) {
    return false;
  }
  if (parseInt(mileage) > max_mileage) {
    return false;
  }
  return true;
};

const helpers = {
  isApproved
};

export default helpers;
