var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dealer-wrapper my-5 my-lg-4"},_vm._l((_vm.dealers.Person),function(dealer,idx){return _c('div',{key:idx,staticClass:"dealer"},[(dealer.Photo != '')?_c('img',{staticClass:"dealer-image",attrs:{"src":dealer.Photo,"alt":dealer.FirstName + ' ' + dealer.LastName}}):_vm._e(),(dealer.Photo == '')?_c('img',{staticClass:"dealer-image",attrs:{"src":require("@/assets/kuva-tulossa.png"),"alt":dealer.FirstName + ' ' + dealer.LastName}}):_vm._e(),_c('p',{staticClass:"dealer-name"},[_c('strong',[_vm._v(" "+_vm._s(dealer.FirstName)+" "),_c('br'),_vm._v(" "+_vm._s(dealer.LastName)+" ")])]),_c('p',{staticClass:"dealer-title"},[_vm._v(_vm._s(_vm._f("capitalize")(dealer.Title)))]),_c('div',[_c('address',{staticClass:"dealer-info"},[_c('ul',[(dealer.TelMobile != '' || dealer.TelNormal != '')?_c('li',{staticClass:"dealer-phone"},[(
                (dealer.TelMobile != '' && dealer.TelNormal != '') ||
                  (dealer.TelMobile != '' && dealer.TelNormal == '')
              )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(dealer.TelMobile),expression:"dealer.TelMobile"}],attrs:{"href":'tel:' + dealer.TelMobile}}):_vm._e(),(dealer.TelMobile == '' && dealer.TelNormal != '')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(dealer.TelNormal),expression:"dealer.TelNormal"}],attrs:{"href":'tel:' + dealer.TelNormal}}):_vm._e(),(dealer.TelMobile == '' && dealer.TelNormal == '')?_c('a',{staticClass:"d-none"}):_vm._e()]):_vm._e(),(dealer.Email != '')?_c('li',{staticClass:"dealer-email"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(dealer.Email),expression:"dealer.Email"}],attrs:{"href":'mailto:' + dealer.Email}})]):_vm._e(),(dealer.HideWhatsApp == false && dealer.WhatsApp != null)?_c('li',{staticClass:"dealer-wa"},[_c('a',{attrs:{"href":'https://wa.me/' +
                  dealer.WhatsApp +
                  '?text=Olen+kiinnostunut+autosta+' +
                  _vm.vehicle.formattedLicensePlateNumber,"target":"_blank","rel":"noopener"}})]):_vm._e(),(
              dealer.HideWhatsApp == false &&
                dealer.WhatsApp == null &&
                dealer.TelMobile.length > 0
            )?_c('li',{staticClass:"dealer-wa"},[_c('a',{attrs:{"href":'https://wa.me/' +
                  dealer.TelMobile +
                  '?text=Olen+kiinnostunut+autosta+' +
                  _vm.vehicle.formattedLicensePlateNumber,"target":"_blank","rel":"noopener"}})]):_vm._e()])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }