<template>
  <div class="single-vehicle" v-if="'licensePlateNumber' in vehicle">
    <nav id="nav" class="container-fluid px-1">
      <div class="bar">
        <button
          class="btn btn-function"
          @click="$router.push({ name: 'Results' })"
        >
          Takaisin hakutulossivulle
        </button>
        <div class="filters"></div>
        <button
          class="btn btn-text"
          @click="$router.push({ name: 'Favorites' })"
          v-if="false != false"
        >
          Suosikit <span class="label">{{ countFavorites }}</span>
        </button>
      </div>
    </nav>
    <heading :image="image" :isApproved="isApproved(vehicle)">
      <!-- <div class="buttons">
        <button
          v-if="!isFavorite(vehicle.licensePlateNumber)"
          @click="setFavorite()"
        >
          Lisää suosikiksi
        </button>
        <button v-else @click="removeFavorite()">
          Poista suosikeista
        </button>
        <button>Tulosta</button>
        <button>Jaa</button>
      </div> -->
    </heading>
    <main class="w-100 position-relative">
      <div class="gallery-wrapper">
        <div class="container-xl">
          <gallery
            :images="vehicle.images.marketingImageUrls"
            @changeImage="changeImage"
          ></gallery>
        </div>
      </div>
      <div class="content-wrapper container-xl">
        <div class="content pb-3">
          <h1 class="mt-2 mb-1">{{ vehicle.make }} {{ vehicle.model }}</h1>
          <p class="mt-0">{{ vehicle.type }}</p>
          <div class="d-block d-lg-none">
            <div class="hinnat">
              <h4 class="pt-1">
                {{ vehicle.price | price }}
              </h4>
              <p
                v-if="
                  vehicle.calculation &&
                    'calculation_data' in vehicle.calculation &&
                    (vehicle.calculation.calculation_data
                      .part_payment_calculation ||
                      vehicle.calculation.calculation_data.flex_calculation)
                "
              >
                {{
                  vehicle.calculation.calculation_data.monthly_installment
                    | price
                }}/kk
                <template
                  v-if="
                    'part_payment_calculation' in
                      vehicle.calculation.calculation_data ||
                      'flex_calculation' in vehicle.calculation.calculation_data
                  "
                >
                  ({{
                    vehicle.calculation.calculation_data
                      .part_payment_calculation
                      ? vehicle.calculation.calculation_data
                          .part_payment_calculation.months
                      : vehicle.calculation.calculation_data.flex_calculation
                          .months
                  }}kk)
                </template>
              </p>
              <p
                v-if="
                  'calculation' in vehicle &&
                    vehicle.calculation &&
                    vehicle.calculation.shippingfee
                "
              >
                Toimituskulu: {{ vehicle.calculation.shippingfee | price }}
              </p>
            </div>
            <div class="d-flex flex-wrap py-2">
              <div class="pr-1 pb-1">
                <template v-if="vehicle">
                  <calculation-modal
                    :vehicle="vehicle"
                    v-if="vehicle.calculation"
                  ></calculation-modal>
                </template>
              </div>
              <modal close-on-click style="display: block;">
                <template v-slot:videoActivator="{ on }">
                  <button class="btn btn-block btn-secondary" @click="on">
                    Sovi videoesittely
                  </button>
                </template>
                <template v-slot:activator="{ on }">
                  <button class="btn btn-block btn-primary" @click="on">
                    Vaihtotarjous tai koeajo?
                  </button>
                </template>
                <template #header="{on}">
                  <h3>Ota yhteyttä</h3>
                  <a @click="on"
                    ><button class="btn btn-inline">
                      Sulje <span class="icon-remove"></span></button
                  ></a>
                </template>
                <template v-slot:default="{ on }">
                  <contact-form :vehicle="vehicle" @close="on"></contact-form>
                </template>
              </modal>
            </div>
          </div>
          <div class="tech py-3 py-md-5 pr-lg-3">
            <h3 class="mb-2">Tekniset tiedot</h3>
            <div class="row no-gutters">
              <div class="col-12 col-sm">
                <div>Vuosimalli</div>
                <strong>{{ vehicle.modelYear }}</strong>
              </div>
              <div class="col-12 col-sm">
                <div>Mittarilukema</div>
                <strong>{{ vehicle.mileage | mileage }}</strong>
              </div>
              <div class="col-12 col-sm">
                <div>Moottoritilavuus</div>
                <strong v-if="'engineVol' in vehicle.technicalInformation"
                  >{{ vehicle.technicalInformation.engineVol }} cm<sup>3</sup>
                </strong>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-sm">
                <div>Polttoaine</div>
                <strong>{{ vehicle.fuelType | fuelType }}</strong>
              </div>
              <div class="col-12 col-sm">
                <div>Rekisterinumero</div>
                <strong>{{ vehicle.formattedLicensePlateNumber }}</strong>
              </div>
              <div class="col-12 col-sm">
                <div>Korimalli</div>
                <strong>{{ vehicle.bodyType | bodyType }}</strong>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-sm">
                <div>Vaihteisto</div>
                <strong
                  v-if="'transmissionType' in vehicle.technicalInformation"
                >
                  {{
                    vehicle.technicalInformation.transmissionType
                      | transmissionType
                  }}
                </strong>
              </div>
              <div class="col-12 col-sm">
                <div>Vetotapa</div>
                <strong v-if="'driveType' in vehicle.technicalInformation">
                  {{ vehicle.technicalInformation.driveType | driveType }}
                </strong>
              </div>
              <div class="col-12 col-sm">
                <div>co<sub>2</sub>-päästöt</div>
                <strong>{{ vehicle.nedcEmissions }} g/km</strong>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-sm">
                <div>Teho</div>
                <strong v-if="'maxPower' in vehicle.technicalInformation">
                  {{ vehicle.technicalInformation.maxPower }} kW
                </strong>
              </div>
              <div class="col-12 col-sm">
                <div>Väri</div>
                <strong>
                  {{ vehicle.colorText.fi }}
                </strong>
              </div>
              <div class="col-12 col-sm">
                <div>Ajoneuvolaji</div>
                <strong>
                  {{ vehicle.vehicleType | vehicleType }}
                </strong>
              </div>
            </div>
            <div class="row no-gutters">
              <div
                class="col-12 col-sm"
                v-if="
                  'fuelEconomyEU' in vehicle &&
                    'combined' in vehicle.fuelEconomyEU &&
                    vehicle.fuelEconomyEU.combined > 0
                "
              >
                <div>Yhdistetty kulutus</div>
                <strong>{{ vehicle.fuelEconomyEU.combined }} l/100 km</strong>
              </div>
              <div
                class="col-12 col-sm"
                v-if="
                  'technicalInformation' in vehicle &&
                    'trunkVolume' in vehicle.technicalInformation &&
                    vehicle.technicalInformation.trunkVolume > 0
                "
              >
                <div>Tavaratilan tilavuus</div>
                <strong
                  >{{ vehicle.technicalInformation.trunkVolume }} litraa</strong
                >
              </div>
              <div
                class="col-12 col-sm"
                v-if="
                  'technicalInformation' in vehicle &&
                    'towingCapacityWithBrakes' in
                      vehicle.technicalInformation &&
                    'towingCapacityWithoutBrakes' in
                      vehicle.technicalInformation &&
                    vehicle.technicalInformation.towingCapacityWithBrakes > 0 &&
                    vehicle.technicalInformation.towingCapacityWithoutBrakes > 0
                "
              >
                <div>Perävaunumassa (jarruilla/ilman)</div>
                <strong>
                  {{ vehicle.technicalInformation.towingCapacityWithBrakes }} kg
                  /
                  {{ vehicle.technicalInformation.towingCapacityWithoutBrakes }}
                  kg
                </strong>
              </div>
            </div>
          </div>
          <div
            class="accessories flex-column mb-3 mb-md-5 mr-lg-3 border-bottom"
          >
            <div
              class="row accordion-header"
              @click="showAccessory = !showAccessory"
            >
              <div class="col">
                <h3 class="mb-2">Varusteet ja lisätiedot</h3>
              </div>
              <div class="col-auto">
                <span class="icon-chevron-down text-red"></span>
              </div>
            </div>
            <div v-if="showAccessory">
              <template v-for="accessory in vehicle.accessories">
                <p :key="accessory.id">{{ accessory.name }}</p>
              </template>
            </div>
          </div>
          <div
            class="p3 mr-lg-3"
            v-if="
              isApproved(vehicle) ||
                ('additional_data' in vehicle && vehicle.additional_data)
            "
          >
            <div class="row">
              <div class="col">
                <h3 class="pb-2">Saatavilla olevat palvelut</h3>
              </div>
            </div>
            <div class="row pb-2" v-if="isApproved(vehicle)">
              <div class="col">
                <h4 class="py-2 border-top">Toyota Approved vaihtoautot</h4>
                <p>
                  Auton hintaan sisältyvä Toyota Approved Vaihtoautot Turva on
                  voimassa 12 kk hankintahetkestä ilman kilometrirajoitusta.
                </p>
              </div>
            </div>
            <div
              class="row pb-2"
              v-if="
                'additional_data' in vehicle &&
                  vehicle.additional_data &&
                  'allowed_products' in vehicle.additional_data &&
                  vehicle.additional_data.allowed_products.maintenance
              "
            >
              <div class="col">
                <h4 class="py-2 border-top">Toyota huoltosopimus</h4>
                <p>
                  Kun hankit vaihtoauton yhteydessä Toyota Huoltosopimuksen,
                  varmistat auton kunnossa pysymisen ja saat käyttöösi
                  huolettoman auton ennalta määritellyin kustannuksin. Voit
                  sisällyttää huoltosopimukseen auton seuraavat kaksi tai neljä
                  huoltoa.
                </p>
                <div
                  v-if="
                    vehicle.calculation &&
                      'calculation_data' in vehicle.calculation &&
                      vehicle.calculation.calculation_data &&
                      'maintenance_prices' in
                        vehicle.calculation.calculation_data
                  "
                >
                  <p>
                    <template
                      v-for="product in vehicle.calculation.calculation_data
                        .maintenance_prices.products"
                    >
                      <span :key="product.service_count" class="mr-3">
                        {{ product.service_count }} huoltoa
                        <strong>{{ product.price.replace(".", ",") }} €</strong>
                      </span>
                    </template>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="row"
              v-if="
                'additional_data' in vehicle &&
                  vehicle.additional_data &&
                  'allowed_products' in vehicle.additional_data &&
                  vehicle.additional_data.allowed_products.flex_calculation
              "
            >
              <div class="col pb-2">
                <h4 class="py-2 border-top">Toyota Easy Osamaksu -rahoitus</h4>
                <p>
                  Toyota Easy Osamaksulla hankit auton järkevällä kuukausierällä
                  ja taatulla hyvityshinnalla. Voit vaihtaa auton säännöllisin
                  väliajoin, halutessasi myös kesken sopimuksen. Sopimuksen
                  lopussa päätät vaihdatko, pidätkö vai palautatko autosi. Jos
                  päätät palauttaa auton, saat siitä vähintään taatun
                  hyvityshinnan.
                </p>
              </div>
            </div>
            <div
              class="row mb-3 mb-md-5"
              v-if="
                'additional_data' in vehicle &&
                  vehicle.additional_data &&
                  'allowed_products' in vehicle.additional_data &&
                  vehicle.additional_data.allowed_products.insurance
              "
            >
              <div class="col">
                <h4 class="py-2 border-top">Toyota Rahoituskasko</h4>
                <p>
                  Kun rahoitat autosi Toyota Finance Finland Oy:ssä, saat siihen
                  halutessasi edullisen Toyota Rahoituskaskon kuukausierällä.
                  Toyota Rahoituskasko sisältää lakisääteisen
                  liikennevakuutuksen ja valitsemasi turvalaajuuden mukaisen
                  kaskovakuutuksen.
                </p>
              </div>
            </div>
          </div>
          <div class="approved-info p-3 mr-lg-3" v-if="isApproved(vehicle)">
            <div class="row">
              <div class="col">
                <h3 class="pb-2">Toyota Approved Vaihtoautot</h3>
                <p>
                  Alle 10-vuotiaat ja enintään 185 000 km ajetut
                  Toyota-vaihtoautot
                </p>
                <ul>
                  <li>
                    <span class="icon-ok text-red"></span> Maksuton 12 kk:n
                    Turva
                  </li>
                  <li>
                    <span class="icon-ok text-red"></span> Ei
                    kilometrirajoitusta
                  </li>
                  <li>
                    <span class="icon-ok text-red"></span> Tarkastettu ja
                    kunnostettu
                  </li>
                  <li>
                    <span class="icon-ok text-red"></span> Hybridiakkuturva
                  </li>
                  <li>
                    <span class="icon-ok text-red"></span> Hybrid Health Check
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="dealer"></div>
        </div>
        <aside class="sidebar">
          <div class="hinnat col-auto pr-1 pr-lg-0 col-lg-12 px-0">
            <h2 class="pb-0 pb-lg-1">
              {{ vehicle.price | price }}
            </h2>
            <p
              v-if="
                vehicle.calculation &&
                  (vehicle.calculation.calculation_data
                    .part_payment_calculation ||
                    vehicle.calculation.calculation_data.flex_calculation)
              "
            >
              {{
                vehicle.calculation.calculation_data.monthly_installment
                  | price
              }}/kk ({{
                vehicle.calculation.calculation_data.part_payment_calculation
                  ? vehicle.calculation.calculation_data
                      .part_payment_calculation.months
                  : vehicle.calculation.calculation_data.flex_calculation
                      .months
              }}kk)
            </p>
            <p
              v-if="
                'calculation' in vehicle &&
                  vehicle.calculation &&
                  vehicle.calculation.shippingfee
              "
            >
              Toimituskulu: {{ vehicle.calculation.shippingfee | price }}
            </p>
          </div>
          <template v-if="vehicle">
            <calculation-modal
              :vehicle="vehicle"
              v-if="vehicle.calculation"
            ></calculation-modal>
          </template>
          <!-- <button class="btn btn-block btn-secondary mb-1">
          Rahoituslaskuri
        </button> -->

          <!-- <button class="btn btn-block btn-primary mb-1">Verkkokauppa</button> -->
          <hr class="d-none d-lg-block my-2" />
          <modal close-on-click style="display:block;">
            <template v-slot:videoActivator="{ on }">
              <button
                class="btn btn-block btn-secondary d-none d-lg-block my-lg-2 videoesittely"
                @click="on"
              >
                <span class="d-none d-lg-block">Sovi videoesittely</span>
              </button>
            </template>
            <template v-slot:activator="{ on }">
              <button
                class="btn btn-block btn-primary ml-0 my-0 my-lg-2 vaihtotarjous"
                @click="on"
              >
                <span class="d-lg-none">Ota yhteyttä</span
                ><span class="d-none d-lg-block"
                  >Vaihtotarjous tai koeajo?</span
                >
              </button>
            </template>
            <template #header="{on}">
              <h3>Ota yhteyttä</h3>
              <a @click="on"
                ><button class="btn btn-inline">
                  Sulje <span class="icon-remove"></span></button
              ></a>
            </template>
            <template v-slot:default="{ on }">
              <contact-form :vehicle="vehicle" @close="on"></contact-form>
            </template>
          </modal>
          <!-- <button class="btn btn-block btn-primary my-1">
          Vaihtotarjous tai koeajo?
        </button> -->
          <div
            class="dealer d-none d-lg-block"
            v-if="dealer && 'operatingCompany' in dealer"
          >
            <strong>Myyjäliike</strong><br />
            <span @click="showInfo = !showInfo">
              {{ dealer.name }}
            </span>
            <div class="more-info" v-if="showInfo">
              <ul>
                <li></li>
              </ul>
            </div>
            <hr class="my-2" v-if="!isJM" />
            <address v-if="!isJM">
              <ul>
                <li class="phone">
                  <a :href="'tel:' + dealer.defaultPhoneNumber">
                    {{ dealer.defaultPhoneNumber }}
                  </a>
                </li>
                <li class="email">
                  <a :href="'mailto:' + dealer.defaultEmail">{{
                    dealer.defaultEmail
                  }}</a>
                </li>
                <li class="web">
                  <a
                    :href="dealer.url.WebsiteURL[0].replace('http:', 'https:')"
                    >{{ dealer.url.WebsiteURL[0] }}</a
                  >
                </li>
              </ul>
            </address>
          </div>
        </aside>
      </div>
      <hr />
      <div class="container-xl mb-5" v-if="dealer">
        <div class="row w-100 py-3 py-md-5">
          <div class="col">
            <h3 class="mb-2">Myyjäliike</h3>
            <address>
              <ul>
                <li>
                  {{ dealer.name }}
                </li>
                <li>
                  {{ dealer.defaultAddress.addressLine1 }}
                </li>
                <li>
                  {{ dealer.defaultAddress.addressLine2 }}
                </li>
                <li>
                  {{ dealer.defaultAddress.zip }}
                  {{ dealer.defaultAddress.city }}
                </li>
              </ul>
            </address>
          </div>
          <div class="col">
            <mgl-map
              :interactive="false"
              :accessToken="mapBoxToken"
              map-style="mapbox://styles/mapbox/streets-v11"
              :zoom="7"
              :center="coordinates"
            >
              <mgl-marker :coordinates="coordinates" color="red"></mgl-marker>
            </mgl-map>
          </div>
        </div>
        <dealers></dealers>
      </div>
    </main>
  </div>
</template>

<script>
import Heading from "@/components/Vehicle/Page/Heading";
import Gallery from "@/components/Vehicle/Page/Gallery";
import Dealers from "@/components/Vehicle/Page/Dealers";
import CalculationModal from "@/components/Vehicle/CalculationModal";
import ContactForm from "@/components/Forms/ContactForm";
import { mapGetters, mapActions } from "vuex";
import { MglMap, MglMarker } from "vue-mapbox";
import config from "@/plugins/config";
import helpers from "@/plugins/helpers";
export default {
  data: () => ({
    selectedImage: 0,
    showInfo: false,
    showAccessory: false,
    isVideo: false
  }),
  metaInfo() {
    let title =
      this.vehicle.make +
      " " +
      this.vehicle.model +
      " (" +
      this.vehicle.modelYear +
      ") | " +
      this.$options.filters.price(this.vehicle.price) +
      " | ";
    if (
      "calculation" in this.vehicle &&
      "calculation_data" in this.vehicle.calculation &&
      this.vehicle.calculation.calculation_data.monthly_installment
    ) {
      title =
        title +
        this.$options.filters.price(
          this.vehicle.calculation.calculation_data.monthly_installment
        ) +
        "/kk | ";
    }
    title = title + this.vehicle.mileage + " km | " + this.vehicle.location;
    let description;
    if ("technicalInformation" in this.vehicle) {
      description =
        "Katso vaihtoauton tiedot. " +
        this.$options.filters.bodyType(this.vehicle.bodyType) +
        ", " +
        this.$options.filters.transmissionType(
          this.vehicle.technicalInformation.transmissionType
        ) +
        ", " +
        this.$options.filters.driveType(
          this.vehicle.technicalInformation.driveType
        ) +
        ", " +
        this.vehicle.technicalInformation.maxPower +
        " kw. ";
      if (this.dealer && "name" in this.dealer) {
        description = description + this.dealer.name;
      }
    } else {
      description = "Katso vaihtoauton tiedot.";
    }
    return {
      title,
      meta: [
        {
          name: "description",
          content: description
        }
      ],
      link: [
        {
          rel: "canonical",
          href: window.location.href
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      vehicle: "vehicle/getVehicle",
      isFavorite: "favorites/isFavorite",
      countFavorites: "favorites/countFavorites",
      getByNMSC: "dealers/getByNMSC"
    }),
    isJM() {
      return window.isJM;
    },
    image() {
      return this.vehicle.images.marketingImageUrls[this.selectedImage];
    },
    dealer() {
      if (!this.vehicle.dealer) return false;
      return this.getByNMSC(this.vehicle.dealer.toyotaDealerId);
    },
    mapBoxToken() {
      return config.mapBoxToken;
    },
    coordinates() {
      if (!this.dealer) return;
      return [
        this.dealer.defaultAddress.geoLocation.lon,
        this.dealer.defaultAddress.geoLocation.lat
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchVehicle: "vehicle/fetchVehicle"
    }),
    changeImage(idx) {
      this.selectedImage = idx;
    },
    setFavorite() {
      this.$store.commit("favorites/ADD_FAVORITE", this.vehicle);
    },
    removeFavorite() {
      this.$store.commit(
        "favorites/REMOVE_FAVORITE",
        this.vehicle.licensePlateNumber
      );
    },
    isApproved(vehicle) {
      return helpers.isApproved(vehicle);
    }
  },
  mounted() {
    if (!("licensePlateNumber" in this.vehicle)) {
      this.fetchVehicle(this.$route.params.id)
        .then()
        .catch(err => {
          console.log(err);
        });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchVehicle(to.params.id)
      .then()
      .catch(err => {
        console.log(err);
      });
    next();
  },
  components: {
    Heading,
    Gallery,
    ContactForm,
    MglMap,
    MglMarker,
    CalculationModal,
    Dealers
  }
};
</script>

<style lang="scss">
.t-vaihtoautohaku .single-vehicle {
  .accessories {
    flex-direction: column;
  }
  .mapboxgl-marker-anchor-center {
    position: absolute;
    top: 0;
  }
  .gallery-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //background: #333;
    z-index: 1;
  }
  .content-wrapper {
    //max-width: 80%;
    margin: auto;
    display: flex;

    .content {
      //flex: 5;
      text-align: left;
      //margin-top: 100px;
      width: calc(100% - 320px);
      @media (max-width: 992px) {
        width: 100%;
      }
      //spacing ratio
      &:before {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        padding-top: 100px;
      }
      .tech {
        .row {
          margin: 0;
          @media (min-width: 576px) {
            padding: 1.25rem 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          }
          @media (max-width: 576px) {
            .col-sm {
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              padding: 0.75rem 0;
            }
            .col-sm div,
            strong {
              display: inline-block;
              width: 50%;
            }
          }
        }
      }
    }
  }
  .sidebar {
    //margin-top: -100px;
    //flex: 2;
    width: 100%;
    background: #fff;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    align-self: start;
    padding: 15px;
    padding-bottom: 30px;
    text-align: left;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    //display: none;
    @media (min-width: 768px) {
      justify-content: center;
    }
    @media (min-width: 992px) {
      justify-content: space-between;
      padding-bottom: 15px;
      display: block;
      position: sticky;
      position: -webkit-sticky;
      top: 67px;
      max-width: 320px;
    }
    .hinnat {
      float: left;
      @media (min-width: 992px) {
        padding-bottom: 2rem;
        float: none;
      }
      @media (max-width: 992px) {
        h2 {
          font-size: 20px;
          line-height: 24px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    a {
      text-decoration: none;
    }
    .btn.vaihtotarjous {
      @media (max-width: 576px) {
        line-height: 1.25;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    .rahoitus {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  address {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      .phone,
      .email,
      .web {
        &:before {
          font-family: Toyota-icon-restyle !important;
          speak: none;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 8px;
        }
      }
      .phone {
        &:before {
          content: "\e944";
        }
      }
      .email {
        &:before {
          content: "\e91c";
        }
      }
      .web {
        &:before {
          content: "\e920";
        }
      }
    }
  }
  h3 {
    text-transform: uppercase;
  }
  .approved-info {
    background: #f0f0f0;
    border-radius: 4px;
    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
}
</style>
