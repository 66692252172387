<template>
  <div class="gallery">
    <slick :options="slickOptions">
      <div v-for="(image, idx) in images" :key="idx">
        <img :src="computedImage(image)" @click="setImage(idx)" />
      </div>
    </slick>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";

export default {
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    slickOptions: {
      dots: false,
      arrows: false,
      slidesToShow: 6,
      centerMode: false,
      focusOnSelect: true,
      draggable: true,
      accessibility: true,
      variableWidth: true,
      swipe: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    }
  }),
  methods: {
    setImage(idx) {
      this.$emit("changeImage", idx);
    },
    computedImage(image) {
      let i = "";
      if (image) {
        if (image.indexOf("http:") < 0) {
          i = process.env.VUE_APP_BASEURL + "images/thumbnail/" + image;
        } else {
          i = image.replace("http:", "https:");
        }
      }
      return i;
    }
  },
  components: {
    Slick
  }
};
</script>

<style lang="scss">
.gallery {
  width: calc(100% - 320px);
  @media (max-width: 992px) {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
}
</style>
