<template>
  <div class="tt-container">
    <i class="fas fa-info-circle"></i>
    <span class="tooltip-text">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tt-container {
  display: inline-block;
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    color: #fff;
    background: #000;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    margin-left: -100px;

    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #000 transparent transparent transparent;
    }
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
