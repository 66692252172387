<template>
  <modal close-on-click style="display: block;">
    <template v-slot:activator="{ on }">
      <button class="btn btn-block btn-secondary rahoitus" @click="on">
        Rahoituslaskuri
      </button>
    </template>
    <template #header="{on}">
      <div></div>
      <div>
        <a @click="on"
          ><button class="btn btn-inline">
            Sulje <span class="icon-remove"></span></button
        ></a>
      </div>
    </template>
    <div class="calculation">
      <h2 class="mb-2">Rahoitus ja palvelut</h2>
      <p v-if="calculation.flex_calculation">
        Toyota Easy Osamaksu on osamaksusopimus suuremmalla viimeisellä erällä
        ja taatulla hyvityshinnalla. Taattu hyvityshinta lasketaan sopimusajan
        ja arvioitujen kilometrien perusteella. Voit muokata rahoituslaskelmaa
        tarpeesi mukaan.
      </p>
      <p v-else>
        Harkitsemasi auton rahoitukseen olemme miettineet sinulle joustavan
        tarpeisiisi mukautuvan ratkaisun. Näet kuukausierän heti ja voit muokata
        alla olevaa
        {{
          calculation.flex_calculation ? "Toyota Easy Osamaksu -" : ""
        }}rahoituslaskelmaa tarpeesi mukaan.
      </p>
      <form class="py-2">
        <div class="row mx-0 align-items-center pb-3 border-bottom">
          <div class="col">
            <span
              >{{
                calculation.flex_calculation
                  ? "Toyota Easy Osamaksu"
                  : "Osamaksun"
              }}
              kuukausierä</span
            >
            <h3 v-if="!isLoading">
              {{ calculation.monthly_installment | price }}/kk
            </h3>
            <div class="tva-loading" v-else>
              <i class="fa icon-refresh fa-spin"></i>
            </div>
          </div>
          <div class="col-auto" v-if="false != false">
            <button class="btn btn-primary" @click.prevent="getCredit()">
              Hae luottopäätös
            </button>
          </div>
        </div>
        <div class="row mx-0 align-items-center py-2 border-bottom">
          <div class="col">
            <p>Auton hinta + lisävarusteet + toimituskulut</p>
          </div>
          <div class="col-auto">
            <span v-if="!isLoading">
              {{ total_price | price }}
            </span>
            <span class="tva-loading" v-else
              ><i class="fa icon-refresh fa-spin"></i
            ></span>
          </div>
        </div>
        <div class="row mx-0 py-2 align-items-center border-bottom">
          <div class="col">
            <p>Rahoitettava osuus</p>
          </div>
          <div class="col-auto">
            <span v-if="!isLoading">
              {{
                calculation.part_payment_calculation
                  ? calculation.part_payment_calculation
                      .fundable_part_with_establishing_cost
                  : calculation.flex_calculation
                      .fundable_part_with_establishing_cost | price
              }}
            </span>
            <span class="tva-loading" v-else
              ><i class="fa icon-refresh fa-spin"></i
            ></span>
          </div>
        </div>
        <div
          class="row mx-0 py-2 align-items-center border-bottom"
          v-if="isFlex"
        >
          <div class="col">
            Taattu hyvityshinta
            <tooltip>
              Hinta, jonka Toyota lupaa vähintään hyvittää sinulle autostasi
              sopimuksen päättyessä.
            </tooltip>
          </div>
          <div class="col-auto">
            <span v-if="!isLoading">
              {{ calculation.flex_calculation.gfv | price }}
            </span>
            <span class="tva-loading" v-else
              ><i class="fa icon-refresh fa-spin"></i
            ></span>
          </div>
        </div>
        <div class="row mx-0 py-2 align-items-center border-bottom">
          <div class="col">Käsiraha</div>
          <div class="w-100 d-block d-md-none"></div>
          <div class="col">
            <div class="slider-container">
              <!-- <input
                type="range"
                :min="downpaymentLimits.min"
                :max="downpaymentLimits.max"
                v-model.trim="$v.downpayment.$model"
                @blur="runCalculation()"
              /> -->
              <input
                type="range"
                :min="downpaymentLimits.min"
                :max="downpaymentLimits.max"
                v-model.trim="$v.downpayment.$model"
                @change="runCalculation()"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="col">
            <fieldset class="form-group">
              <!-- <input
                class="form-control"
                v-model.trim="$v.downpayment.$model"
                @blur="runCalculation()"
              /> -->
              <input
                class="form-control"
                v-model.lazy.trim="$v.downpayment.$model"
                @change="runCalculation()"
                :disabled="isLoading"
              />
            </fieldset>
          </div>
        </div>
        <div class="row mx-0 py-2 align-items-center border-bottom">
          <div class="col">
            Viimeinen suurempi erä
            <tooltip v-if="isFlex">
              Voit itse määrittää viimeisen erän suuruuden, kuitenkin vähintään
              10% auton hinnasta ja enintään samansuuruinen kuin taattu
              hyvityshinta.
            </tooltip>
          </div>
          <div class="w-100 d-block d-md-none"></div>
          <div class="col">
            <div class="slider-container">
              <input
                type="range"
                :min="balloonLimits.min"
                :max="balloonLimits.max"
                v-model.trim="$v.balloon.$model"
                @change="runCalculation()"
                step="0.01"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="col">
            <fieldset class="form-group">
              <input
                class="form-control"
                v-model.lazy.trim="$v.balloon.$model"
                @change="runCalculation()"
                :disabled="isLoading"
              />
            </fieldset>
          </div>
        </div>
        <div class="row mx-0 py-2 align-items-center border-bottom">
          <div class="col">
            Sopimuksen kesto
          </div>
          <div class="w-100 d-block d-md-none"></div>
          <div class="col">
            <div class="slider-container">
              <input
                type="range"
                :min="monthLimits.min"
                :max="monthLimits.max"
                step="12"
                v-model="months"
                @change="runCalculation()"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="col">
            <fieldset class="form-group">
              <select
                v-model="months"
                @change="runCalculation()"
                :disabled="isLoading"
              >
                <option
                  v-for="(month, idx) in (monthLimits.max -
                    monthLimits.min +
                    12) /
                    12"
                  :key="month"
                  :value="idx * 12 + monthLimits.min"
                  >{{ idx * 12 + monthLimits.min }}</option
                >
              </select>
            </fieldset>
          </div>
        </div>
        <div
          class="row mx-0 py-2 align-items-center border-bottom"
          v-if="isFlex"
        >
          <div class="col">
            Ajokilometrit vuodessa
          </div>
          <div class="w-100 d-block d-md-none"></div>
          <div class="col">
            <div class="slider-container">
              <input
                type="range"
                :min="kilometerLimits.min"
                :max="kilometerLimits.max"
                v-model.trim="annual_km"
                step="5000"
                @change="runCalculation()"
                :disabled="isLoading"
              />
            </div>
          </div>
          <div class="col">
            <fieldset class="form-group">
              <input
                class="form-control"
                v-model.lazy.trim="annual_km"
                :disabled="isLoading"
                @change="runCalculation()"
              />
            </fieldset>
          </div>
        </div>
        <div
          class="row mx-0 py-2 align-items-center"
          v-if="allowed_products && allowed_products.maintenance"
        >
          <div class="col">
            <strong>Toyota Huoltosopimus</strong>
          </div>
        </div>
        <div
          class="row mx-0 pb-1 border-bottom"
          v-if="
            allowed_products &&
              allowed_products.maintenance &&
              'maintenance_prices' in calculation &&
              'products' in calculation.maintenance_prices
          "
        >
          <div
            class="col-12 col-md"
            v-for="product in calculation.maintenance_prices.products"
            :key="product.service_count"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="product.service_count"
              :id="'maint-' + product.service_count"
              :checked="maintenance == product.service_count"
              @change.prevent="
                maintenance == product.service_count
                  ? (maintenance = false)
                  : (maintenance = product.service_count)
              "
              :disabled="isLoading"
            /><label
              :for="'maint-' + product.service_count"
              class="form-check-inline"
              >{{ product.service_count }} huoltoa
              <strong>{{ product.price | price }}</strong>
            </label>
          </div>
        </div>
        <div
          class="row mx-0 pt-2 pb-2 align-items-center"
          v-if="allowed_products && allowed_products.insurance"
        >
          <div class="col">
            <strong>Toyota Rahoituskasko</strong>
          </div>
        </div>
        <div
          class="row mx-0 pb-1"
          v-if="allowed_products && allowed_products.insurance"
        >
          <div class="col">
            <div class="checkbox">
              <input
                type="checkbox"
                id="insurance"
                v-model="insurance.selected"
                :disabled="isLoading"
              />
              <label for="insurance"
                >Toyota Rahoituskasko
                <strong
                  >{{
                    "insurance" in calculation && calculation.insurance
                      ? calculation.insurance.monthly_installment + " €/kk"
                      : ""
                  }}
                </strong>
              </label>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-1" v-if="insurance.selected">
          <div class="col">
            <fieldset class="form-group">
              <input
                class="form-control"
                placeholder="Vakuutuksenottajan syntymäaika"
                v-model="insurance.birth"
                :disabled="isLoading"
                type="text"
                onfocus="this.type = 'date'"
                onblur="!this.value ? this.type = 'text' : this.type = 'date'"
                title="Vakuutuksenottajan syntymäaika"
              />
            </fieldset>
          </div>
          <div class="col">
            <fieldset class="form-group">
              <input
                placeholder="Postinumero"
                class="form-control"
                v-model="insurance.zip"
                :disabled="isLoading"
              />
            </fieldset>
          </div>
          <div class="col">
            <button
              class="btn"
              @click.prevent="runCalculation()"
              :disabled="isLoading"
            >
              Laske hinta
            </button>
          </div>
        </div>
        <div
          class="d-flex"
          v-if="
            insurance.selected &&
              'insurance_prices' in calculation &&
              calculation.insurance_prices
          "
        >
          <div
            class="col"
            v-for="(price, idx) in calculation.insurance_prices"
            :key="'insurance-' + idx"
          >
            <div class="price">{{ price.monthly_installment | price }}/kk</div>
            <div class="deductible">
              Törmäysturvan omavastuu: {{ price.deductible }} €
            </div>
            <button
              class="btn btn-function my-1"
              @click.prevent="setDeductible(price.deductible)"
              :class="
                insurance.deductible === price.deductible ? 'selected' : ''
              "
              :disabled="isLoading"
            >
              {{
                insurance.deductible === price.deductible
                  ? "Valittu"
                  : "Valitse"
              }}
            </button>
          </div>
        </div>
      </form>
      <div class="calculation-info">
        <div class="d-flex pt-2">
          <div class="col">
            <h3 class="pb-2">Rahoituslaskelma</h3>
            <p>
              Esimerkkilaskelma, tarkennetun ja sitovan tarjouksen saa
              jälleenmyyjältä.
            </p>
          </div>
        </div>
        <!-- <div class="d-flex py-2 border-bottom">
          <div class="col">
            Toimituskulut
          </div>
          <div class="col-auto">
            {{ vehicle.calculation.shippingfee }}
            €
          </div>
        </div> -->
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Luoton määrä
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? calculation.flex_calculation.fundable_part
                : calculation.part_payment_calculation.fundable_part
            }}
            €
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Korko (%)
          </div>
          <div class="col-auto">
            {{
              (isFlex
                ? calculation.flex_calculation.interest * 100
                : calculation.part_payment_calculation.interest * 100
              ).toFixed(2)
            }}
            %
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Luottosuhteen perustamiskulu (€)
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? calculation.flex_calculation.establishingcost
                : calculation.part_payment_calculation.establishingcost
            }}
            €
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Käsittelymaksu (€/kk)
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? calculation.flex_calculation.handlingfee
                : calculation.part_payment_calculation.handlingfee
            }}
            €/kk
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Todellinen vuosikorko
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? (
                    calculation.flex_calculation.effective_interest * 100
                  ).toFixed(2)
                : (
                    calculation.part_payment_calculation.effective_interest *
                    100
                  ).toFixed(2)
            }}
            %
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Luottokustannukset
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? calculation.flex_calculation.credit_costs
                : calculation.part_payment_calculation.credit_costs
            }}
            €
          </div>
        </div>
        <div class="d-flex py-2 border-bottom">
          <div class="col">
            Luoton ja kustannusten yhteismäärä
          </div>
          <div class="col-auto">
            {{
              isFlex
                ? calculation.flex_calculation.credit_plus_expenses
                : calculation.part_payment_calculation.credit_plus_expenses
            }}
            €
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <small
            >Rahoitusyhtiö Toyota Finance Finland Oy, Korpivaarantie 1, 01450
            Vantaa. Rahoitus vaatii hyväksytyn luottopäätöksen ja
            kaskovakuutuksen.</small
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Axios from "axios";
import { mapActions, mapGetters } from "vuex";
import config from "@/plugins/config";
import { required } from "vuelidate/lib/validators";
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: {
    vehicle: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    set: {
      downpayment: null,
      balloon: null,
      months: null,
      km: null,
    },
    yearly_km: 15000,
    maintenance: null,
    insurance: {
      selected: false,
      birth: null,
      zip: null,
      deductible: 200,
    },
    approved_ov: false,
    approved_ext: false,
    allowed_products: {
      maintenance: false,
      flex_calculation: false,
      insurance: false,
    },
  }),
  computed: {
    ...mapGetters({
      hasCalculation: "calculations/hasCalculation",
      getCalculation: "calculations/getCalculation",
      precondition: "calculations/getPrecondition",
      isLoading: "calculations/getLoading",
    }),
    calculation() {
      return this.hasCalculation(this.vehicle.licensePlateNumber)
        ? this.getCalculation(this.vehicle.licensePlateNumber)
        : this.vehicle.calculation.calculation_data;
    },
    isFlex() {
      return this.calculation.flex_calculation !== null;
    },
    fundable() {
      let string = "Auto";
      if (this.maintenance) {
        string = string + " + Huoltosopimus";
      }
      if (this.approved_ov || this.approved_ext) {
        string = string + " + Approved Turva";
      }
      return string;
    },
    downpayment: {
      get() {
        return this.set.downpayment
          ? this.set.downpayment
          : this.isFlex
          ? this.calculation.flex_calculation.downpayment
          : this.calculation.part_payment_calculation.downpayment;
      },
      set(val) {
        this.set.downpayment = val;
      },
    },
    balloon: {
      get() {
        let b = this.set.balloon
          ? this.set.balloon
          : this.isFlex
          ? this.calculation.flex_calculation.balloon
          : this.calculation.part_payment_calculation.balloon;
        b = parseFloat(b);
        if (b < this.balloonLimits.min) {
          b = this.balloonLimits.min;
        }
        if (b > this.balloonLimits.max) {
          b = this.balloonLimits.max;
        }
        return b;
      },
      set(val) {
        this.set.balloon = val;
      },
    },
    months: {
      get() {
        return this.set.months
          ? this.set.months
          : this.isFlex
          ? this.calculation.flex_calculation.months
          : this.calculation.part_payment_calculation.months;
      },
      set(val) {
        this.testCalculationKm(parseInt(val));
        this.set.months = parseInt(val);
      },
    },
    km: {
      get() {
        return parseInt(this.yearly_km) * (this.months / 12);
        // return this.set.km
        //   ? this.set.km
        //   : this.isFlex
        //   ? this.calculation.flex_calculation.km / (this.months / 12)
        //   : this.calculation.part_payment_calculation.km / (this.months / 12);
      },
      set(val) {
        this.yearly_km = val * (this.months / 12);
        this.set.km = val;
      },
    },
    annual_km: {
      get() {
        return this.yearly_km;
      },
      set(val) {
        this.testCalculationAge(val);
        this.yearly_km = val;
      },
    },
    downpaymentLimits() {
      let limits = {
        min: this.isFlex
          ? this.vehicle.price *
            (this.precondition.flex_calculation.used_cars.downpayment
              .min_consumer /
              100)
          : this.calculation.part_payment_calculation.downpayment_min,
        max: this.isFlex
          ? this.calculation.flex_calculation.downpayment_max
          : this.calculation.part_payment_calculation.downpayment_max,
      };
      return limits;
    },
    balloonLimits() {
      let limits = {
        min: this.isFlex
          ? this.calculation.flex_calculation.balloon_min
          : this.calculation.part_payment_calculation.balloon_min,
        max: this.isFlex
          ? this.calculation.flex_calculation.balloon_max
          : this.calculation.part_payment_calculation.balloon_max,
      };
      return limits;
    },
    monthLimits() {
      let limits = {
        min: this.isFlex
          ? this.calculation.flex_calculation.month_limits[0]
          : this.calculation.part_payment_calculation.month_limits[0],
        max: this.isFlex
          ? this.calculation.flex_calculation.month_limits[1]
          : this.calculation.part_payment_calculation.month_limits[1],
      };

      return limits;
    },
    kilometerLimits() {
      return {
        min: this.precondition.flex_calculation.used_cars.km.min_annual,
        max: this.precondition.flex_calculation.used_cars.km.max_annual,
      };
    },
    total_price() {
      let total = parseFloat(this.vehicle.price);
      if (
        "shippingfee" in this.vehicle.calculation &&
        this.vehicle.calculation.shippingfee
      ) {
        total = total + parseFloat(this.vehicle.calculation.shippingfee);
      }
      if (this.approved_ov) {
        total = total + 99;
      }
      if (this.approved_ext) {
        total = total + 399;
      }
      if (this.maintenance && this.vehicle.calculation.maintenance) {
        total = total + parseFloat(this.vehicle.calculation.maintenance.price);
      }
      return total;
    },
  },
  validations: {
    downpayment: {
      required,
      minValue(val) {
        return parseInt(val) >= this.downpaymentLimits.min;
      },
      maxValue(val) {
        return parseInt(val) <= this.downpaymentLimits.max;
      },
    },
    balloon: {
      required,
      minValue(val) {
        return parseInt(val) >= this.balloonLimits.min;
      },
      maxValue(val) {
        return parseInt(val) <= this.balloonLimits.max;
      },
    },
  },
  methods: {
    ...mapActions({
      calculate: "calculations/calculate",
    }),
    getCredit() {},
    runCalculation() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let ac = 0;
      if (this.approved_ov) {
        ac = ac + 99;
      }
      if (this.approved_ext) {
        ac = ac + 399;
      }
      let payload = {
        isFlex: this.isFlex,
        vehicle: this.vehicle.id,
        calculation: {
          km: this.km,
          downpayment: this.downpayment,
          balloon: this.balloon,
          months: this.months,
          additionalCosts: ac,
          maintenance: this.maintenance,
        },
      };
      if (this.vehicle.calculation.shippingfee) {
        payload.calculation.shippingfee = this.vehicle.calculation.shippingfee;
      }
      if (
        this.insurance.selected &&
        this.insurance.birth !== null &&
        this.insurance.zip !== null
      ) {
        payload.calculation.insurance = this.insurance;
      }
      this.calculate(payload)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setDeductible(deductible) {
      this.insurance.deductible = deductible;
      this.runCalculation();
    },
    getDetails() {
      return Axios.get(
        config.baseUrl + "calculation/details/" + this.vehicle.id
      );
    },
    testCalculationKm(months) {
      let precondition = this.precondition.flex_calculation.used_cars;
      let km = this.yearly_km * (months / 12);
      if (km > precondition.km.max_contract) {
        let rounded =
          Math.floor(precondition.km.max_contract / (months / 12) / 5000) *
          5000;
        if (rounded < this.kilometerLimits.min) {
          rounded = this.kilometerLimits.min;
        }
        if (rounded > this.kilometerLimits.max) {
          rounded = this.kilometerLimits.max;
        }
        this.yearly_km = rounded;
        // this.km = (precondition.km.max_contract / (months / 12)) * months;
      } else if (km < precondition.km.min_contract) {
        // this.yearly_km = precondition.km.min_contract / (this.months / 12);
      } else if (km / (this.months / 12) < precondition.km.min_annual) {
        //
      }
    },
    testCalculationAge(km) {
      let precondition = this.precondition.flex_calculation.used_cars;
      km = km * (this.months / 12);
      if (km > precondition.km.max_contract) {
        this.months =
          Math.floor(precondition.km.max_contract / (km / (this.months / 12))) *
          12;
      } else if (km < precondition.km.min_contract) {
        this.months =
          (precondition.km.min_contract / (km / (this.months / 12))) * 12;
      }
    },
  },
  mounted() {
    this.yearly_km = this.isFlex
      ? this.calculation.flex_calculation.km / (this.months / 12)
      : this.calculation.part_payment_calculation.km / (this.months / 12);

    this.getDetails()
      .then((result) => {
        this.allowed_products = result.data.allowed_products;
      })
      .catch(() => {});
  },
  watch: {
    "insurance.selected"(v) {
      if (!v) {
        this.runCalculation();
      }
    },
    approved_ov() {
      this.runCalculation();
    },
    approved_ext() {
      this.runCalculation();
    },
    maintenance() {
      this.runCalculation();
    },
  },
};
</script>

<style lang="scss" scoped>
.t-vaihtoautohaku .calculation {
  .tva-loading {
    color: #e50000;
  }
  .form-group {
    margin-bottom: 0;
  }
  .selected {
    position: relative;
    &:before {
      font-family: Toyota-icon-restyle !important;
      content: "\e942";
      position: absolute;
      top: 0;
      left: 12px;
      width: 24px;
      height: 24px;
      color: #fff;
    }
  }
  .slider-container {
    padding: 0 5px;
    max-width: 100%;
    > input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 2px;
      background: #606060;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
      background-repeat: repeat-x;
      border-radius: 2px;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      @media (min-width: 992px) {
        min-width: 150px;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #b4b4b4;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
}
</style>
