<template>
  <div class="dealer-wrapper my-5 my-lg-4">
    <div v-for="(dealer, idx) in dealers.Person" :key="idx" class="dealer">
      <img
        v-if="dealer.Photo != ''"
        :src="dealer.Photo"
        :alt="dealer.FirstName + ' ' + dealer.LastName"
        class="dealer-image"
      />
      <img
        v-if="dealer.Photo == ''"
        src="@/assets/kuva-tulossa.png"
        :alt="dealer.FirstName + ' ' + dealer.LastName"
        class="dealer-image"
      />
      <p class="dealer-name">
        <strong>
          {{ dealer.FirstName }} <br />
          {{ dealer.LastName }}
        </strong>
      </p>
      <p class="dealer-title">{{ dealer.Title | capitalize }}</p>
      <div>
        <address class="dealer-info">
          <ul>
            <li
              v-if="dealer.TelMobile != '' || dealer.TelNormal != ''"
              class="dealer-phone"
            >
              <a
                v-if="
                  (dealer.TelMobile != '' && dealer.TelNormal != '') ||
                    (dealer.TelMobile != '' && dealer.TelNormal == '')
                "
                :href="'tel:' + dealer.TelMobile"
                v-tooltip="dealer.TelMobile"
              ></a>
              <a
                v-if="dealer.TelMobile == '' && dealer.TelNormal != ''"
                :href="'tel:' + dealer.TelNormal"
                v-tooltip="dealer.TelNormal"
              ></a>
              <a
                v-if="dealer.TelMobile == '' && dealer.TelNormal == ''"
                class="d-none"
              ></a>
            </li>
            <li v-if="dealer.Email != ''" class="dealer-email">
              <a :href="'mailto:' + dealer.Email" v-tooltip="dealer.Email"></a>
            </li>
            <li
              v-if="dealer.HideWhatsApp == false && dealer.WhatsApp != null"
              class="dealer-wa"
            >
              <a
                :href="
                  'https://wa.me/' +
                    dealer.WhatsApp +
                    '?text=Olen+kiinnostunut+autosta+' +
                    vehicle.formattedLicensePlateNumber
                "
                target="_blank"
                rel="noopener"
              ></a>
            </li>
            <li
              v-if="
                dealer.HideWhatsApp == false &&
                  dealer.WhatsApp == null &&
                  dealer.TelMobile.length > 0
              "
              class="dealer-wa"
            >
              <a
                :href="
                  'https://wa.me/' +
                    dealer.TelMobile +
                    '?text=Olen+kiinnostunut+autosta+' +
                    vehicle.formattedLicensePlateNumber
                "
                target="_blank"
                rel="noopener"
              ></a>
            </li>
          </ul>
        </address>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dealers: {
      Person: []
    }
  }),
  computed: {
    ...mapGetters({
      vehicle: "vehicle/getVehicle"
    })
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_BASEURL +
          "sellers/" +
          this.vehicle.dealer.toyotaDealerId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        }
      )
      .then(response => {
        this.dealers.Person = response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value
        .toString()
        .replace(/(?:\/)/g, ",\n")
        .split(" ,")
        .join(",");
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>

<style lang="scss">
.dealer-wrapper {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 1rem;
  @media (max-width: 992px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-bottom: 6rem;
  }
  .dealer {
    .dealer-image {
      width: 100%;
      height: auto;
      margin-bottom: 0.5rem;
    }
    .dealer-name {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: 0.5rem;
    }
    .dealer-title {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: 1rem;
    }
    address.dealer-info {
      ul {
        display: flex;
        justify-content: space-evenly;
        list-style: none;
        margin: 0;
        padding: 0;
        li.dealer-phone a,
        li.dealer-email a,
        li.dealer-wa a {
          text-decoration: none;
          &:before {
            font-size: 22px;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .dealer-phone a {
          &:before {
            content: "\e944";
            font-family: Toyota-icon-restyle;
          }
        }
        .dealer-email a {
          &:before {
            content: "\e91c";
            font-family: Toyota-icon-restyle;
          }
        }
        .dealer-wa a {
          &:before {
            content: url("https://vaihtoautot.toyota.fi/images/wa-logo.png");
            zoom: 75%;
          }
        }
      }
    }
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 14px;
  font-family: ToyotaType-Book;
  .tooltip-inner {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 16px;
    padding: 6px 15px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }
  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
