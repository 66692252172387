<template>
  <div class="contact-form">
    <form>
      <div class="row">
        <div class="col">
          <div class="checkbox">
            <input type="checkbox" id="cb-vaihto" v-model="vaihto" />
            <label for="cb-vaihto">
              Haluan vaihtotarjouksen
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="checkbox">
            <input type="checkbox" id="cb-video" v-model="video" />
            <label for="cb-video">
              Sovi videoesittely
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="checkbox">
            <input type="checkbox" id="cb-testDrive" v-model="testDrive" />
            <label for="cb-testDrive">
              Varaan koeajon
            </label>
          </div>
        </div>
      </div>
      <div class="car-info mt-2 py-2" v-if="showVehicle">
        <h2 class="mb-2">{{ vehicle.make }} {{ vehicle.model }}</h2>
        <p>{{ vehicle.type }}</p>
        <p>
          <strong>{{ vehicle.price | price }}</strong>
        </p>
      </div>
      <div class="testdrive-info mb-2" v-if="testDrive">
        <p>Toivottu koeajoaika</p>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.testDriveDate.$error }"
            >
              <!-- <input
                type="date"
                v-model="testDriveDate"
                placeholder="Toivottu päivämäärä"
                class="form-control"
              /> -->
              <date-picker
                v-model="testDriveDate"
                placeholder="Toivottu päivämäärä"
                :disabled-date="date => dayOfWeek(date) == 0"
                :lang="lang"
                input-class="form-control"
                :formatter="momentDate"
                @change="testDriveTime = null"
                value-type="YYYY-MM-DD"
              ></date-picker>
              <p class="text-error" v-if="$v.testDriveDate.$error">
                Koeajopäivä on pakollinen tieto koeajoa varatessa.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.testDriveTime.$error }"
            >
              <!-- <input
                type="time"
                v-model="testDriveTime"
                placeholder="Toivottu kellonaika"
                class="form-control"
              /> -->
              <date-picker
                :disabled="!testDriveDate || dayOfWeek(testDriveDate) == 0"
                v-model="testDriveTime"
                placeholder="Toivottu kellonaika"
                input-class="form-control"
                type="time"
                :show-second="false"
                :minute-step="30"
                format="HH:mm"
                :time-picker-options="timePickerOptions(testDriveDate)"
                :key="dayOfWeek(testDriveDate)"
                value-type="HH:mm"
              ></date-picker>
              <p class="text-error" v-if="$v.testDriveTime.$error">
                Koeajoaika on pakollinen tieto koeajoa varatessa.
              </p>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="video-info mb-2" v-if="video">
        <p>Toivottu ajankohta videoesittelylle</p>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.videoDate.$error }"
            >
              <!-- <input
                type="date"
                v-model="testDriveDate"
                placeholder="Toivottu päivämäärä"
                class="form-control"
              /> -->
              <date-picker
                v-model="videoDate"
                placeholder="Toivottu päivämäärä"
                :disabled-date="date => dayOfWeek(date) == 0"
                :lang="lang"
                input-class="form-control"
                :formatter="momentDate"
                @change="videoTime = null"
                value-type="YYYY-MM-DD"
              ></date-picker>
              <p class="text-error" v-if="$v.videoDate.$error">
                Päivämäärä on pakollinen tieto videoesittelyä varatessa.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.videoTime.$error }"
            >
              <!-- <input
                type="time"
                v-model="testDriveTime"
                placeholder="Toivottu kellonaika"
                class="form-control"
              /> -->
              <date-picker
                :disabled="!videoDate || dayOfWeek(videoDate) == 0"
                v-model="videoTime"
                placeholder="Toivottu kellonaika"
                input-class="form-control"
                type="time"
                :show-second="false"
                :minute-step="30"
                format="HH:mm"
                :time-picker-options="timePickerOptions(videoDate)"
                :key="dayOfWeek(videoDate)"
                value-type="HH:mm"
              ></date-picker>
              <p class="text-error" v-if="$v.videoTime.$error">
                Kellonaika on pakollinen tieto videoesittelyä varatessa.
              </p>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="contact-info my-2">
        <p class="mb-2">Yhteystietosi</p>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.consumer.givenName.$error }"
            >
              <input
                type="text"
                v-model.trim="$v.consumer.givenName.$model"
                placeholder="Etunimi"
                class="form-control"
              />
              <p class="text-error" v-if="$v.consumer.givenName.$error">
                Etunimi on pakollinen tieto.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.consumer.familyName.$error }"
            >
              <input
                type="text"
                v-model="consumer.familyName"
                placeholder="Sukunimi"
                class="form-control"
              />
              <p class="text-error" v-if="$v.consumer.familyName.$error">
                Sukunimi on pakollinen tieto.
              </p>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{
                'form-group--error': $v.consumer.phone.$error
              }"
            >
              <input
                type="text"
                v-model="consumer.phone"
                placeholder="Puhelin"
                class="form-control"
              />
              <p class="text-error" v-if="$v.consumer.phone.$error">
                Puhelinnumero on pakollinen tieto.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{
                'form-group--error':
                  $v.consumer.email.$error || !$v.consumer.email.email
              }"
            >
              <input
                type="email"
                v-model="consumer.email"
                placeholder="Sähköposti"
                class="form-control"
              />
              <p class="text-error" v-if="$v.consumer.email.$error">
                Sähköpostiosoite on pakollinen tieto.
              </p>
              <p class="text-error" v-if="!$v.consumer.email.email">
                Syötä toimiva sähköpostiosoite.
              </p>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset class="form-group">
              <textarea
                :placeholder="
                  this.video
                    ? 'Kerro ennakkoon mitä erityisesti haluaisit tietää/nähdä autosta'
                    : 'Lisätietokysely'
                "
                v-model="message"
                class="form-control"
              >
              </textarea>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="contact-method mb-2">
        <div class="row">
          <div class="col">
            <p>Toivottu yhteydenottotapa</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="radio">
              <input
                type="radio"
                name="contact-method"
                value="phone"
                v-model="consumer.contactMethod"
                id="cm-phone"
              />
              <label for="cm-phone">
                Puhelin
              </label>
            </div>
            <div class="radio">
              <input
                type="radio"
                name="contact-method"
                value="email"
                id="cm-email"
                v-model="consumer.contactMethod"
              />
              <label for="cm-email">
                Sähköposti
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="vaihturi" v-if="vaihto">
        <div class="row">
          <div class="col">
            <p>Oman auton tiedot</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.tradeIn.regNumber.$error }"
            >
              <input
                type="text"
                placeholder="Rekisterinumero"
                class="form-control"
                v-model="tradeIn.regNumber"
              />
              <p class="text-error" v-if="$v.tradeIn.regNumber.$error">
                Rekisterinumero on pakollinen tieto vaihtotarjouksen tekemistä
                varten.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.tradeIn.mileage.$error }"
            >
              <input
                type="text"
                placeholder="Mittarilukema"
                class="form-control"
                v-model="tradeIn.mileage"
              />
              <p class="text-error" v-if="$v.tradeIn.mileage.$error">
                Mittarilukema on pakollinen tieto vaihtotarjouksen tekemistä
                varten.
              </p>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.tradeIn.make.$error }"
            >
              <input
                type="text"
                placeholder="Merkki"
                class="form-control"
                v-model="tradeIn.make"
              />
              <p class="text-error" v-if="$v.tradeIn.make.$error">
                Malli on pakollinen tieto vaihtotarjouksen tekemistä varten.
              </p>
            </fieldset>
          </div>
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.tradeIn.model.$error }"
            >
              <input
                type="text"
                placeholder="Malli"
                class="form-control"
                v-model="tradeIn.model"
              />
              <p class="text-error" v-if="$v.tradeIn.model.$error">
                Merkki on pakollinen tieto vaihtotarjouksen tekemistä varten.
              </p>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset
              class="form-group"
              :class="{ 'form-group--error': $v.tradeIn.initRegYear.$error }"
            >
              <input
                type="text"
                placeholder="Vuosimalli"
                class="form-control"
                v-model="tradeIn.initRegYear"
              />
              <p class="text-error" v-if="$v.tradeIn.initRegYear.$error">
                Vuosimalli on pakollinen tieto vaihtotarjouksen tekemistä
                varten.
              </p>
            </fieldset>
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset class="form-group">
              <textarea
                v-model="tradeIn.extra"
                placeholder="Autosi varusteet ja lisätiedot"
                class="form-control"
              ></textarea>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
    <!-- <template #footer>
      <button>Lähetä</button>
    </template> -->
    <div class="row">
      <div class="col" style="float: right;">
        <button
          class="btn btn-primary"
          :disabled="submitStatus === 'PENDING'"
          @click="sendForm()"
        >
          Lähetä
        </button>
        <p class="text-error" v-if="submitStatus === 'ERROR'">
          Tarkista kentät.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/plugins/config";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    lang: {
      formatLocale: {
        firstDayOfWeek: 1
      }
    },
    vaihto: false,
    testDrive: false,
    video: false,
    tradeIn: {
      make: "",
      model: "",
      regNumber: "",
      initRegYear: "",
      mileage: "",
      extra: ""
    },
    testDriveDate: null,
    testDriveTime: null,
    videoDate: null,
    videoTime: null,
    message: "",
    consumer: {
      givenName: "",
      familyName: "",
      email: "",
      phone: "",
      contactMethod: "email"
    },
    submitStatus: null,
    sent: false,
    momentDate: {
      stringify: date => {
        return date ? moment(date).format("DD.MM.YYYY") : "";
      },
      parse: value => {
        return value ? moment(value, "DD.MM.YYYY").toDate() : null;
      }
    }
  }),
  validations() {
    let v = {
      consumer: {
        givenName: {
          required
        },
        familyName: {
          required
        },
        email: {
          requiredIf: requiredIf(() => {
            return this.consumer.contactMethod == "email";
          }),
          email
        },
        phone: {
          requiredIf: requiredIf(() => {
            return this.consumer.contactMethod == "phone";
          })
        }
      }
    };
    if (this.video) {
      Object.assign(v, {
        videoDate: {
          required
        },
        videoTime: {
          required
        }
      });
    }
    if (this.vaihto) {
      Object.assign(v, {
        tradeIn: {
          regNumber: {
            required
          },
          mileage: {
            required
          },
          model: {
            required
          },
          make: {
            required
          },
          initRegYear: {
            required
          }
        }
      });
    }
    if (this.testDrive) {
      Object.assign(v, {
        testDriveDate: {
          required
        },
        testDriveTime: {
          required
        }
      });
    }
    console.log(v);
    return v;
  },
  computed: {
    ...mapGetters({
      hasCalculation: "calculations/hasCalculation",
      getCalculation: "calculations/getCalculation",
      isVideo: "isVideo"
    }),
    showVehicle() {
      return this.vaihto || this.testDrive || this.video;
    },
    includeCalculation() {
      return this.hasCalculation(this.vehicle.licensePlateNumber);
    }
  },
  methods: {
    dayOfWeek(day) {
      return moment(day, "DD.MM.YYYY").format("d");
    },
    timePickerOptions(day) {
      let dayofweek = moment(day, "DD.MM.YYYY").format("d");
      let options = {
        start: "00:00",
        step: "00:30",
        end: "23:30",
        format: "HH:mm"
      };
      switch (dayofweek.toString()) {
        case "0":
          options = {
            start: "00:00",
            step: "00:30",
            end: "23:30",
            format: "HH:mm"
          };
          break;
        case "6":
          options = {
            start: "10:00",
            step: "00:30",
            end: "14:00",
            format: "HH:mm"
          };
          break;
        default:
          options = {
            start: "09:00",
            step: "00:30",
            end: "16:30",
            format: "HH:mm"
          };
          break;
      }
      return options;
    },
    sendForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v.$invalid);
        this.submitStatus = "ERROR";
      } else {
        let payload = {
          vaihto: this.vaihto,
          video: this.video,
          videoDate: this.videoDate,
          videoTime: this.videoTime,
          testDrive: this.testDrive,
          testDriveDate: this.testDriveDate,
          testDriveTime: this.testDriveTime,
          tradeIn: this.tradeIn,
          includeCalculation: this.includeCalculation,
          message: this.message,
          consumer: this.consumer,
          vehicle: this.vehicle.id
        };
        if (payload.includeCalculation) {
          payload.calculation = this.hasCalculation(
            this.vehicle.licensePlateNumber
          )
            ? this.getCalculation(this.vehicle.licensePlateNumber)
            : this.vehicle.calculation.calculation_data;
        }
        if (window.isJM) {
          payload.source = "jm";
        }
        return (
          axios
            .post(config.baseUrl + "send", payload)
            .then(() => {
              this.sent = true;
              if (window.ga) {
                if (window.isJM) {
                  window.ga("rollupTracker.send", {
                    hitType: "pageview",
                    page:
                      window.location.origin +
                      window.location.pathname +
                      "#/auto/vaihtotarjous-koeajo/lähetetty"
                  });
                  window.ga("jmTracker.send", {
                    hitType: "pageview",
                    page:
                      window.location.origin +
                      window.location.pathname +
                      "#/auto/vaihtotarjous-koeajo/lähetetty"
                  });
                } else {
                  window.ga("send", {
                    hitType: "pageview",
                    page:
                      window.location.origin +
                      window.location.pathname +
                      "#/auto/vaihtotarjous-koeajo/lähetetty"
                  });
                }
                if (!payload.vaihto && !payload.testDrive && !payload.video) {
                  if (window.isJM) {
                    window.ga("jmTracker.send", {
                      hitType: "event",
                      eventCategory: "Vaihtoautot",
                      eventAction: "Yhteydenotto",
                      eventLabel: "Lisätietokysely"
                    });
                    window.ga("rollupTracker.send", {
                      hitType: "event",
                      eventCategory: "Vaihtoautot",
                      eventAction: "Yhteydenotto",
                      eventLabel: "Lisätietokysely"
                    });
                  } else {
                    window.ga("send", {
                      hitType: "event",
                      eventCategory: "Vaihtoautot",
                      eventAction: "Yhteydenotto",
                      eventLabel: "Lisätietokysely"
                    });
                  }
                } else {
                  if (payload.vaihto) {
                    if (window.isJM) {
                      window.ga("rollupTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Vaihtotarjous"
                      });
                      window.ga("jmTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Vaihtotarjous"
                      });
                    } else {
                      window.ga("send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Vaihtotarjous"
                      });
                    }
                  }
                  if (payload.testDrive) {
                    if (window.isJM) {
                      window.ga("jmTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Koeajo"
                      });
                      window.ga("rollupTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Koeajo"
                      });
                    } else {
                      window.ga("send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Koeajo"
                      });
                    }
                  }
                  if (payload.video) {
                    if (window.isJM) {
                      window.ga("jmTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Videoesittely"
                      });
                      window.ga("rollupTracker.send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Videoesittely"
                      });
                    } else {
                      window.ga("send", {
                        hitType: "event",
                        eventCategory: "Vaihtoautot",
                        eventAction: "Yhteydenotto",
                        eventLabel: "Videoesittely"
                      });
                    }
                  }
                }
              }
            })
            .catch(err => {
              console.log(err);
            }),
          (this.submitStatus = "PENDING"),
          setTimeout(() => {
            this.submitStatus = "OK";
            this.$emit("close", true);
          }, 500)
        );
      }
    }
  },
  mounted() {
    if (this.isVideo) {
      this.video = true;
    }
    if (window.ga) {
      console.log("ga found: sending virtual pageview");
      window.ga("send", {
        hitType: "pageview",
        page:
          window.location.origin +
          window.location.pathname +
          "#/auto/vaihtotarjous-koeajo"
      });
      console.log(
        "pageview sent: " +
          window.location.origin +
          window.location.pathname +
          "#/auto/vaihtotarjous-koeajo"
      );
    }
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.t-vaihtoautohaku {
  .form-group {
    margin-bottom: 1.5rem;
  }
  .mx-datepicker {
    width: 100%;
  }
}
</style>
