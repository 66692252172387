<template>
  <header class="heading position-relative">
    <div class="container-xl">
      <div class="image">
        <div v-if="isApproved">
          <img
            class="toyota-approved"
            src="@/images/toyota-approved.svg"
            alt="Toyota Approved vaihtoauto"
          />
        </div>
        <img :src="computedImage" alt="" />
      </div>
    </div>
    <slot></slot>
  </header>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    isApproved: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedImage() {
      let image = "";
      if (this.image) {
        if (this.image.indexOf("http:") < 0) {
          image = process.env.VUE_APP_BASEURL + "images/large/" + this.image;
        } else {
          image = this.image.replace("http:", "https:");
        }
      }
      return image;
    }
  }
};
</script>

<style lang="scss">
.heading {
  background-color: #333;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  @media (max-width: 992px) {
    .container-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &:before {
    @media (min-width: 540px) {
      padding-top: calc(900 / 1600 * 100%); //aspect-ratio
    }
    @media (min-width: 960px) {
      padding-top: calc(800 / 1600 * 100%); //aspect-ratio
    }
  }
  .image {
    overflow: hidden;
    text-align: center;
    position: relative;
    @media (min-width: 768px) {
      padding-top: calc(900 / 1600 * 100%); //aspect-ratio
    }
    @media (min-width: 960px) {
      padding-top: calc(800 / 1600 * 100%); //aspect-ratio
    }
    & > img {
      @media (min-width: 768px) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        height: 100%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
  .toyota-approved {
    position: absolute;
    bottom: 30px;
    left: 15px;
    z-index: 1;
    max-width: 200px;
    transform: initial;
    @media (min-width: 992px) {
      bottom: 15px;
      left: 0;
      max-width: 220px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.24) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 70%,
      rgba(0, 0, 0, 0.24) 100%
    );
  }

  .buttons {
    width: 80%;
    text-align: right;
    margin: 0 auto;
  }
}
</style>
